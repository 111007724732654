import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable()
export class ConfigService {
  private config;
  public ready$ = new Subject<ConfigService>();

  // Getters
  get API_URI() {
    return this.getProperty('API_URI');
  }

  get auth_config() {
      return this.getProperty("auth_config");
  }

  constructor(private http: HttpClient) {}

  load(): Promise<any> {
    const promise = this.http.get('./assets/environment.json').toPromise();

    // Sets config then returns promise to APP_INITIALISER
    promise.then((config) => {
      this.config = config;
      this.ready$.next(this);
    });

    return promise;
  }

  private getProperty(property: string): any {
    if (!this.config) {
      console.error(`No Config data loaded`);
      return;
    }

    if (!this.config[property]) {
      console.error(`Cannot find config property ` + property);
      return;
    }

    return this.config[property];
  }
}
