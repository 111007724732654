import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';

import { Observable, from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { ConfigService } from '../services/config/config.service';
import { HttpErrorService } from '../services/http-error.service';
import { MatDialog } from '@angular/material/dialog';
import { InformationDialogComponent } from '../information-dialog/information-dialog/information-dialog.component';

@Injectable()
export class TokenInterceptor implements HttpInterceptor
{
    constructor(private oauthService: OAuthService, private configService: ConfigService, private httpErrorService: HttpErrorService,
        public dialog: MatDialog)
    {
    }

    handleResponseError(error, request?, next?): Observable<HttpEvent<any>> {
        // Invalid token error
        if (error.status === 401) {
            // if not local API call return error
            if(!request.url.startsWith(this.configService.API_URI)) return of(error);
            // refresh token and retry API call
            return from(this.oauthService.refreshToken()).pipe(
                switchMap((): Observable<HttpEvent<any>> => {
                        request = this.cloneAndAddHeader(request);
                        return next.handle(request);
                }),
                catchError(e => {
                    if (e.status !== 401) {
                        return this.handleResponseError(e);
                    } else {
                        // if fails again logout user
                        this.oauthService.logOut();
                    }
                }));
        }

        // Access denied error
        else if (error.status === 403) {
            const dialogRef = this.dialog.open(InformationDialogComponent, {
                    data: {
                      message: "User is not whitelisted to use this application, logging out.",
                    },
                  });

            dialogRef.afterClosed().subscribe(() => {
                this.oauthService.logOut();
            });
        }
        this.httpErrorService.handleHttpError(error);
        return of(error);
    }

    cloneAndAddHeader(request: HttpRequest<any>): HttpRequest<any> {
        const token = this.oauthService.getAccessToken();
        return request.clone(
            {
                setHeaders:
                    {
                        Authorization: `Bearer ${token}`
                    }
            });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        if(request.url.startsWith(this.configService.API_URI))
        {
            request = this.cloneAndAddHeader(request);
        }

        return next.handle(request).pipe(catchError(error => {
                return this.handleResponseError(error, request, next);
         }));
    }
}