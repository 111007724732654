import { OAuthStorage } from "angular-oauth2-oidc";

/**
 * Namespace selected storage type to avoid clash with apps on same domain
 */
export class NSStorage implements OAuthStorage {
    private _ns: string;
    /**
     *
     * @param ns namespace for keys ns:key
     * @param storage preferred storage option localStorage || sessionStorage
     */
    constructor(ns: string | null, private storage: OAuthStorage){
        console.log('init storage', storage.constructor.name, ns);
        this._ns = ns === null ? '' : ns + ':'
    }
    public getItem(key: string): string | null {
        return this.storage.getItem(`${this._ns}${key}`);
    }
    public  removeItem(key: string): void {
        return this.storage.removeItem(`${this._ns}${key}`);
    }
    public  setItem(key: string, data: string): void {
        return this.storage.setItem(`${this._ns}${key}`, data);
    }
}

export function storageFactory() : OAuthStorage {
    return new NSStorage(null, localStorage);
}