import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
interface JWTToken {role, emails, email, sub, group, tenant_id, tenant_name};

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private roles: string[];
  private email: string = null;
  private sub: string = null;
  private group: string = null;
  private tenantid: string = null;
  private tenantname: string = null;

  // Getters
  get roleList() {
      return this.roles;
  }

  get username() {
      return this.email;
  }

  get userId() {
      return this.sub;
  }

  get userGroup() {
      return this.group;
  }

  get TenantName() {
      return this.tenantname;
  }
  get TenantId() {
      return this.tenantid;
  }

  constructor(private oauthService: OAuthService)
  {
      if (this.roles == null)
      {
          if (localStorage.access_token != null)
          {
              this.setRolesFromToken();
          }
      }
  }

  setRolesFromToken(): void {
      console.log('get claims in Permissions Service', this.oauthService.getIdentityClaims());
      let tokenPayload = <JWTToken>this.oauthService.getIdentityClaims();

      this.roles = [tokenPayload['extension_Role']];
      this.tenantid = tokenPayload['extension_tenant_id'];
      this.tenantname = tokenPayload['extension_tenant_name'];
      this.sub = tokenPayload['sub'];
      console.log("multi-tenant:"+JSON.stringify(this.roles)+" tenantid:"+this.tenantid+" "+ this.tenantname+" userid:"+this.sub);
      if(tokenPayload.emails!=null && tokenPayload.emails.length>0)
      {
          this.email = tokenPayload.emails[0];
      }
      if(this.email==null)
      {
          this.email = tokenPayload.email;
      }
  }
    roleCheck(roleList: string[]): boolean
    {
        if(this.roles==null)
        {
            this.setRolesFromToken();
        }


        if (this.roles == null)
        {
            return;
        }
        return this.roles.some(role => roleList.includes(role));
    }
}
