import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private oAuthService: OAuthService, private permissionsService: PermissionsService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.oAuthService.hasValidIdToken() && this.oAuthService.hasValidAccessToken()) {
        return true;
    }

    const interval = setInterval(() => {
      if (this.oAuthService.jwks == null) return;
      clearInterval(interval);
      this.oAuthService.initImplicitFlow(JSON.stringify({
          returnUrl: state.url,
      }));
    }, 100);

    return false;
  }
}
