import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpErrorService } from './services/http-error.service';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './core/core.module';
import { ApmModule } from '@elastic/apm-rum-angular';
import { CommonModule } from '@angular/common';
import { ConfigService } from './services/config/config.service';
import { CustomerManagementService } from './services/customer-management/customer-management.service';
import { ConfirmDialogComponent, ConfirmDialogComponentDialog } from './confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { initAuth } from './app-init/auth.init';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { storageFactory } from './app-init/storage.init';
import { TokenInterceptor } from './interceptors/token-interceptor';
import { InformationDialogComponent } from './information-dialog/information-dialog/information-dialog.component';


export function initConfig(config: ConfigService) {
  return () => {
    return config.load();
  };
}

@NgModule({
  declarations: [AppComponent,
    ConfirmDialogComponent,
    ConfirmDialogComponentDialog,
    InformationDialogComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule,
    CommonModule,
    HttpClientModule,
    ApmModule,
    CoreModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    OAuthModule.forRoot({
        resourceServer: {
            sendAccessToken: true,
        },
    }),
    MatDialogModule
  ],
  providers: [
    ConfigService,
    CustomerManagementService,
    HttpErrorService,
    {
        provide: OAuthStorage,
        useFactory: storageFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [ConfigService],
    },
    {
        provide: APP_INITIALIZER,
        useFactory: initAuth,
        multi: true,
        deps: [
            ConfigService,
            OAuthService
        ],
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
    },
    // {
    //   provide: ErrorHandler,
    //   useClass: ApmErrorHandler,
    //   deps: [Router],
    // },

  ],
  entryComponents: [ConfirmDialogComponentDialog],
  bootstrap: [AppComponent],
})
export class AppModule {
  // constructor(service: ApmService) {
  //   // API is exposed through this apm instance
  //   const apm = service.init({
  //     serviceName: 'DeviceManagement',
  //     serverUrl: 'https://apmserver.insights.synxcloud.com/',
  //   });
  //   apm.setUserContext({
  //     username: 'foo',
  //     id: 'bar',
  //   });
  // }
}
