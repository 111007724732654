import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth-guard/auth.guard';

const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "home/device-management",
    pathMatch: 'full'
  },
  {
      path: "login",
      redirectTo: "home/device-management",
      pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home-module/home.module').then((module) => module.HomeModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
